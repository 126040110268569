<template>
  <div class="auth-page">
    <div class="auth-page__fields">
      <UIButton
        id="back-button"
        left-icon="back"
        class="auth-page__back"
        variant="ghost"
        @click="navigateTo({ name: 'index' })"
      >
        Back
      </UIButton>
      <div class="auth-page__title">Log in</div>
      <div class="auth-page__buttons">
        <UIButton id="continue-with-google" left-icon="google" variant="secondary" full-width @click="login"
          >Continue with Google</UIButton
        >
        <NuxtLink no-prefetch to="login/email">
          <UIButton id="continue-with-email" left-icon="email" variant="secondary" full-width
            >Continue with email</UIButton
          >
        </NuxtLink>
      </div>
      <div class="auth-page__description">Don't you have an account? <NuxtLink to="signup">Sign up</NuxtLink></div>
      <div class="auth-page__terms-and-conditions-privacy-policy">
        <NuxtLink to="https://www.termsfeed.com/live/c23f6b23-65b3-46fb-a21e-b51b16c11013" external target="_blank">
          Terms & Conditions
        </NuxtLink>
        <span class="auth-page__terms-and-conditions-privacy-policy-divider"></span>
        <NuxtLink to="https://www.termsfeed.com/live/89f49d1e-cf5e-4d1c-9423-d25e9ca68e4d" external target="_blank">
          Privacy Policy
        </NuxtLink>
      </div>
    </div>
    <div class="auth-page__img">
      <img width="521" height="714" src="/images/login-page-image.png" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { SITE_NAME } from '~/constants'

useHead({
  title: `${SITE_NAME} | Log in  `,
})

definePageMeta({
  layout: 'auth',
  middleware: 'is-auth-route',
})

const { login } = useGoogleLogin()
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
@import '@/styles/mixins.scss';

@include baseAuthPage();
</style>
